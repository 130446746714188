<div class="safe-padding" *ngIf="groupedResourceList.loading || groupedResourceList.error == null">
    <div class="switch">
        <div class="selected-bg" [class.all]="filterUpdate?.showOnlyAvailable === false"></div>
        <div (click)="showOnlyAvailable()" class="value" [class.active]="filterUpdate?.showOnlyAvailable === true">
            {{ translation.outlookAddin.onlyAvailable | transloco }}
        </div>
        <div (click)="showOnlyAvailable()" class="value" [class.active]="filterUpdate?.showOnlyAvailable === false">
            {{ translation.outlookAddin.allResources | transloco }}
        </div>
    </div>
    <assist-textbox [showClear]="true" [formControl]="search" [iconBefore]="ICONS.cueSearch"></assist-textbox>
</div>

<ng-container *ngIf="groupedResourceList">
    <cue-loading *ngIf="groupedResourceList.loading"></cue-loading>
    <div class="resources-list">
        <ng-container *ngIf="!groupedResourceList.loading && groupedResourceList.error">
            <div class="loading-error">
                {{ translation.outlookAddin.errorDescription | transloco }}
                <button (click)="refresh.emit()" class="small">
                    <cue-icon [icon]="ICONS.cueRefresh"></cue-icon>
                    {{ translation.outlookAddin.refresh | transloco }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="!groupedResourceList.loading && !groupedResourceList.error">
            <div>
                <div class="accordion" [class.expanded]="!isItemShrinked(i)" *ngFor="let group of groupedResourceList.data; let i = index">
                    <div class="accordion-header" (click)="expandShrinkGroup(i)">
                        <div class="first-part">
                            <p class="heading">
                                {{ group.resourcePropertyName | localizedString }}
                                <span class="description">({{ group.resources?.length }})</span>
                            </p>
                        </div>
                        <div class="end-part">
                            <div class="arrow">
                                <cue-icon [icon]="ICONS.cueArrowDown"></cue-icon>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-content" *ngIf="!isItemShrinked(i)">
                        <div
                            *ngFor="let item of group.resources"
                            [class.selected]="isResourceSelected(item.resourceId)"
                            class="resource-item"
                            (click)="selectDeselectResource(item.resourceId, $event, item.isAssignedTo)"
                            [class.active]="isResourceSelected(item.resourceId)"
                        >
                            <div class="resource-wrapper">
                                <div class="room-name">
                                    <div
                                        *ngFor="let status of item.status"
                                        class="availability"
                                        [ngStyle]="{ 'background-color': status.color }"
                                    ></div>
                                    <cue-icon *ngIf="item.isAssignedTo | assignTo" [icon]="item.isAssignedTo | assignTo"></cue-icon>
                                    {{ (item.resourceDisplayNameForApp | localizedString) || (item.resourceDisplayName | localizedString) }}
                                </div>
                                <div class="breadcrumb">
                                    <span *ngFor="let item of item.locations; let i = index; let last = last">
                                        <cue-icon [icon]="ICONS.cueArrowRight" *ngIf="i !== 0"></cue-icon>
                                        {{ item.displayName }}
                                    </span>
                                </div>
                                <div class="availability-tag">
                                    <div class="dot" [class.available]="item.isAvailable" [class.unavailable]="!item.isAvailable"></div>
                                    <span *ngIf="item.isAvailable">{{ translation.outlookAddin.available | transloco }}</span>
                                    <span *ngIf="!item.isAvailable">{{ translation.outlookAddin.unavailable | transloco }}</span>
                                    <div class="info">
                                        <cue-icon
                                            *ngIf="!item.isAvailable"
                                            [icon]="ICONS.cueInfo"
                                            (mouseenter)="changeCurrentOpenedTimelineDetail(item.resourceDisplayName, $event)"
                                            (mouseleave)="changeCurrentOpenedTimelineDetail(null, $event)"
                                        >
                                        </cue-icon>
                                        <addin-resource-availability
                                            *ngIf="timelineDetailItemId === item.resourceDisplayName"
                                            [isRestrictedResource]="item.isAssignedTo === resourceAssignType.Restricted"
                                            [timeline]="item.timeLine"
                                            [startDate]="resourceFilter.start"
                                            [endDate]="resourceFilter.end"
                                        >
                                        </addin-resource-availability>
                                    </div>
                                </div>
                            </div>
                            <div class="buttons">
                                <button
                                    [disabled]="item.isAssignedTo === resourceAssignType.Restricted"
                                    (click)="selectDeselectResource(item.resourceId, $event, item.assignTo)"
                                    class="link"
                                    [class.primary]="!isResourceSelected(item.resourceId)"
                                    [class.error]="isResourceSelected(item.resourceId)"
                                >
                                    <cue-icon
                                        [icon]="ICONS.cueAction"
                                        [title]="translation.outlookAddin.chooseResource | transloco"
                                        *ngIf="!isResourceSelected(item.resourceId)"
                                    ></cue-icon>
                                    <cue-icon
                                        [icon]="ICONS.cueClose"
                                        [title]="translation.outlookAddin.removeResource | transloco"
                                        *ngIf="isResourceSelected(item.resourceId)"
                                    ></cue-icon>
                                </button>
                                <button (click)="showResourceDetail(item.resourceId, $event)" class="link secondary">
                                    <cue-icon [icon]="ICONS.cueSearch" [title]="translation.outlookAddin.cueSearch | transloco"></cue-icon>
                                </button>
                                <button class="link secondary" (click)="navigateToResourceDetailInAssist(item.resourceGuid, $event)">
                                    <cue-icon
                                        [icon]="ICONS.cueExternalLink"
                                        [title]="translation.outlookAddin.cueExternalLink | transloco"
                                    ></cue-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="selectedResourceDetail">
    <addin-resource-summary-detail
        (refresh)="onDetailRefresh()"
        (closeResourceDetail)="selectedResourceDetail = null"
        [resourceDetail]="resourceDetail$ | async"
        [isResourceSelected]="isResourceSelected(selectedResourceDetail.resourceId)"
        (selectDeselectResource)="selectDeselectResourceFromDetail($event)"
    >
    </addin-resource-summary-detail>
</ng-container>
