<ng-container *ngIf="!isRestrictedResource">
    <p *ngIf="!isSameDate()">
        {{ translation.outlookAddin.availability | transloco }}
        {{ startDate | date: 'dd.MM. HH:mm' }} - {{ endDate | date: 'dd.MM. HH:mm' }}
    </p>
    <p *ngIf="isSameDate()">
        {{ translation.outlookAddin.availability | transloco }} {{ startDate | date: 'HH:mm' }} -
        {{ endDate | date: 'HH:mm' }}
    </p>

    <div class="timeline">
        <ng-container *ngFor="let timeWindow of timelineWindowList">
            <ng-container *ngIf="timeWindow | resourceTimelineStateToColor as item">
                <span [style.background]="item.color"></span>
            </ng-container>
        </ng-container>
    </div>
    <div class="times">
        <span>
            <ng-container *ngIf="!isSameDate()">{{ startDate | date: 'dd.MM. HH:mm' }}</ng-container>
            <ng-container *ngIf="isSameDate()">{{ startDate | date: 'HH:mm' }}</ng-container>
        </span>
        <span>
            <ng-container *ngIf="!isSameDate()">{{ endDate | date: 'dd.MM. HH:mm' }}</ng-container>
            <ng-container *ngIf="isSameDate()">{{ endDate | date: 'HH:mm' }}</ng-container>
        </span>
    </div>
</ng-container>

<ng-container *ngIf="isRestrictedResource">
    <p class="text-center">
        {{ translation.outlookAddin.resourceRestricted | transloco }}
    </p>
    <span class="text-center restricted-description">
        {{ translation.outlookAddin.chooseAnotherResource | transloco }}
    </span>
</ng-container>
