import { inject, Input, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomUnsafeSanitizerDoNotUseMeService } from '../services/custom-unsafe-sanitizer-do-not-use-me.service';

@Pipe({
  name: 'cueCustomUnsafeSanitize',
  standalone: true,
  pure: true,
})
export class CustomUnsafeSanitizePipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);
  private readonly customSanitizer = inject(CustomUnsafeSanitizerDoNotUseMeService);

  transform(value: string | number, removeExternalLinks: boolean): unknown {
    if (value === null || value === undefined || typeof value !== 'string') {
      return value;
    }

    if (removeExternalLinks) {
      return this.sanitizer.bypassSecurityTrustHtml(this.customSanitizer.SanitizeHtml(this.removeExternalLinksIfRequested(value)));
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(this.customSanitizer.SanitizeHtml(value));
    }
  }

  private removeExternalLinksIfRequested(htmlString: string) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const links = doc.querySelectorAll('a[href]');

    links.forEach((link) => {
      link.removeAttribute('href');
    });

    return doc.body.innerHTML;
  }
}
