import { Injectable } from '@angular/core';
import * as localForage from 'localforage';
import { Config, ResourceDetail } from '../models';
import { AssistConfiguration, AssistConfigurationBM, AssistResourceBM, fromLocalized, LocalizedString, ServerType } from '@cue/api';
import { CalendarAvailability } from '@cue/calendars';
import { Memoize } from 'typescript-memoize';
import { LicensingService } from '@cue/licensing';
import { distinctUntilChanged } from 'rxjs';
import { distinctBy, onlyUnique } from '../utils';
import { localized } from '@cue/translate';

type Configuration = AssistConfigurationBM &
  Config & {
    scope: string;
    redirectUri: string;
  };

interface ConfigurationCache {
  data: string;
  timestamp: string;
  version: number;
}

const CONFIGURATION_CACHE_KEY = 'ConfigurationCache';

@Injectable()
export class ConfigService {
  private CURRENT_VERSION = 47;
  private _config: Configuration;

  backgroundColor(availability: CalendarAvailability): string {
    switch (availability) {
      case CalendarAvailability.free:
        return this.value.design.background.reservableResource.free;
      case CalendarAvailability.reserved:
        return this.value.design.background.reservableResource.reserved;
      case CalendarAvailability.closed:
        return this.value.design.background.reservableResource.closed;
      case CalendarAvailability.limited:
        return this.value.design.background.reservableResource.limited;
      case CalendarAvailability.tentative:
        return this.value.design.background.reservableResource.tentative;
      case CalendarAvailability.assignedToOthers:
        return this.value.design.border.assignedToOther;
      case CalendarAvailability.assignedToYou:
        return this.value.design.border.assignedToYou;
      case CalendarAvailability.maxUtilization:
        return this.value.design.background.reservableResource.limited;
      case CalendarAvailability.currentEvent:
        return 'rgba(35, 60, 90)';
      default:
        return this.value.design.background.reservableResource.tentative;
    }
  }

  prebootstrapLoad(): Promise<any> {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      let cachedConfiguration: ConfigurationCache;
      request.addEventListener('error', (e) => {
        reject('Cannot load config file.');
      });
      request.addEventListener('load', (_) => {
        if (request.status === 200) {
          try {
            const config = JSON.parse(request.responseText) as Config;
            const request2 = new XMLHttpRequest();
            request2.addEventListener('error', (e) => {
              reject('Cannot connect to API server, check if it is available');
            });
            request2.addEventListener('load', () => {
              if (request2.status !== 200 && request2.status !== 204) {
                reject(request2.statusText);
              }
              let response: AssistConfiguration = null;
              if (request2.status === 204) {
                response = JSON.parse(cachedConfiguration.data) as AssistConfiguration;
              }

              if (request2.status === 200) {
                try {
                  let cachedHeader = undefined;
                  try {
                    cachedHeader = request2.getResponseHeader('CachedConfigurationTimeStamp');
                  } catch {
                    cachedHeader = null;
                  }
                  if (cachedHeader) {
                    cachedConfiguration = {
                      data: request2.responseText,
                      timestamp: cachedHeader,
                      version: this.CURRENT_VERSION,
                    };
                    localForage.setItem(CONFIGURATION_CACHE_KEY, cachedConfiguration);
                    response = JSON.parse(cachedConfiguration.data) as AssistConfiguration;
                  } else {
                    localForage.removeItem(CONFIGURATION_CACHE_KEY);
                    response = JSON.parse(request2.responseText);
                  }
                } catch (e) {
                  reject(e);
                }
              }
              if (response == null) {
                localForage.removeItem(CONFIGURATION_CACHE_KEY);
                console.error('Spatna konfigurace na serveru, /api/assist/configurations nevratil zadna data!');
                reject('API is not configured. Edit the settings in CUE Admin.');
                return;
              }

              if (!response.assistConfigured) {
                // http://localhost:4201/qr/eaceec29-eb29-4390-833f-3b59f45c5001
                if (response.navigationConfigured && response.navigationUrl && location.pathname.includes('qr/')) {
                  const qrRequest = new XMLHttpRequest();
                  const pathNames = location.pathname.split('/');
                  const id = pathNames[pathNames.length - 1];
                  const qrUrl = config.apiURL + '/api/qrcode/info/' + id;

                  qrRequest.addEventListener('error', (e) => {
                    reject('Cannot load QR Code info file.');
                    return;
                  });
                  qrRequest.addEventListener('load', (_) => {
                    if (qrRequest.status === 200) {
                      const response: any = JSON.parse(qrRequest.responseText);
                      const hasLocation = response.data?.hasLocation;
                      const startAreaId = response.data?.startAreaId;
                      const qrCodeId = response.data?.qrId;

                      if (hasLocation) {
                        window.location.href = response.data.navigationUrl + '?startAreaId=' + startAreaId + '&locationId=' + qrCodeId;
                        return;
                      } else {
                        reject('cannot load qr code');
                        return;
                      }
                    } else {
                      reject('Assist is not configured. Edit the settings in CUE Admin.');
                      return;
                    }
                  });
                  qrRequest.open('GET', qrUrl);
                  qrRequest.send();
                } else {
                  localForage.removeItem(CONFIGURATION_CACHE_KEY);
                  reject('Assist is not configured. Edit the settings in CUE Admin');
                }
                return;
              }

              const configuration = response.asistConfiguration;
              this._config = {
                ...configuration,
                apiURL: config.apiURL,
                calendarURL: config.calendarURL,
                redirectUri: location.origin + '/account/oauth-callback',
                scope:
                  configuration.serverType === 'gspace'
                    ? 'openid email profile  https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
                    : 'offline_access User.ReadBasic.All openid profile email Calendars.Read.Shared Calendars.ReadWrite Calendars.ReadWrite.Shared Calendars.Read Contacts.Read Contacts.Read.Shared ' +
                      (configuration.enableGroupFinding ? 'Group.Read.All' : ''),
              };

              localStorage.setItem('LOGIN_MODE', this._config.serverType);
              resolve(null);
            });
            let configurationUrl = config.apiURL + '/api/assist/configurations';

            localForage.getItem<ConfigurationCache>(CONFIGURATION_CACHE_KEY).then((cache) => {
              if (cache !== null && cache.version >= this.CURRENT_VERSION) {
                // Je nacachovano
                cachedConfiguration = cache;
                configurationUrl = configurationUrl + '?cachedTimestamp=' + encodeURIComponent(cache.timestamp);
              }
              request2.open('GET', configurationUrl);
              request2.send();
            });
          } catch (e) {
            localForage.removeItem(CONFIGURATION_CACHE_KEY);
            reject(e);
          }
        } else {
          reject(request.statusText);
        }
      });
      request.open('GET', '/config.json');
      request.send();
    });
  }

  get value(): Configuration {
    if (!this._config)
      throw Error('Value of config service is not initialized, are you sure you have called it in initializer / pre bootstrap?');
    return this._config;
  }

  getResourceTypeFromId(resourceTypeId: number) {
    return this.value.resourceTypeInfos.find((x) => x.resourceTypeId === resourceTypeId);
  }

  getFiltersFromResource(
    resource: AssistResourceBM | ResourceDetail,
  ): { name: LocalizedString; value: any; dataTypeId: number; filterable: boolean; visible: boolean }[] {
    const resourceTypeId = resource.resourceTypeId;
    const filters = this.value.filters.filter((x) => x.resourceTypeId === resourceTypeId);
    const computed = filters.map((filter) => {
      const properties = resource.properties.filter((property) => property.propertyId === filter.id);
      let value: any = null;
      switch (filter.dataTypeId) {
        case 1: {
          value = properties.length > 0 ? properties[0].valueBoolean : null;
          break;
        }
        case 2: {
          value = properties.length > 0 ? properties[0].valueNumber : null;
          break;
        }
        case 3: {
          value = properties.length > 0 ? properties[0].valueDecimal : null;
          break;
        }
        case 4: {
          value = properties.length > 0 ? properties[0].valueText ?? '' : null;
          break;
        }
        case 6: {
          value = properties.length > 0 ? properties[0].valueHTMLContent : null;
          break;
        }
        case 5: {
          value = filter.choices
            .filter((choice) => properties.find((p) => p.valueChoiceIds.find((vch) => vch === choice.key)) != null)
            .map((x) => ({
              title: x.value,
              imageUrl: x.imageUrl,
              imagePreferCover: x.imagePreferCover,
            }));
          break;
        }
        default:
          value = null;
          break;
      }

      return {
        name: filter.name,
        value: value,
        visible: filter.visible,
        filterable: filter.filterable,
        dataTypeId: filter.dataTypeId,
      };
    });

    return computed.filter((c) => !Array.isArray(c.value) || c.value.length > 0);
  }

  @Memoize((resources: AssistResourceBM[], resourceTypeId: number, activeLang: string, defaultLang: string) => {
    return resourceTypeId + activeLang + defaultLang;
  })
  getFilterableResourcesFilteredByResourceTypeId(resources: AssistResourceBM[], resourceTypeId, activeLang: string, defaultLang: string) {
    return this.getFilterableResources(resources, activeLang, defaultLang).filter((x) => x.resourceTypeId == resourceTypeId);
  }

  @Memoize((resources: AssistResourceBM[], resourceTypeId: number) => {
    return resourceTypeId;
  })
  getFilterableResourcesForCombo(resources: AssistResourceBM[], activeLang: string, defaultLang: string) {
    const allData = resources
      .map((x) => ({
        ...x,
        filterName:
          fromLocalized(x.displayNameForApp, activeLang, defaultLang) ?? fromLocalized(x.displayName, activeLang, defaultLang) ?? x.name,
      }))
      .sort((a, b) => a.filterName.localeCompare(b.filterName));
    return distinctBy(allData, (x) => x.idForFiltering);
  }

  @Memoize((resources: AssistResourceBM[], resourceTypeId: number, activeLang, defaultLang) => {
    return resourceTypeId + activeLang + defaultLang;
  })
  getFilterableResources(resources: AssistResourceBM[], activeLang: string, defaultLang: string) {
    return resources
      .filter((x) => this.isRestrictedResourceVisible(x))
      .map((x) => ({
        ...x,
        filterName: fromLocalized(x.displayNameForApp, activeLang, defaultLang) ?? x.name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  isRestrictedResourceVisible(resource: AssistResourceBM) {
    return (
      this.value.resourceVisibilityRestriction == 1 || resource.conditions.reservedTo == null || resource.conditions.reservedTo == 'you'
    );
  }

  getEnumerableFiltersByResourceTypeId(resource: AssistResourceBM | ResourceDetail) {
    return this.getFiltersFromResource(resource).filter((x) => x.dataTypeId === 5);
  }

  getSimpleFiltersByResourceTypeId(resource: AssistResourceBM | ResourceDetail) {
    return this.getFiltersFromResource(resource).filter((x) => x.dataTypeId !== 5);
  }

  getFilteredResourceTypes(resources: AssistResourceBM[], licenseService: LicensingService, mode: ServerType) {
    let groups = [];
    if (this.value.resourceVisibilityRestriction == 1) {
      groups = this.value.resourceTypeInfos.filter((resourceType) => {
        return (
          resources.find((r) => r.resourceTypeId == resourceType.resourceTypeId && licenseService.availableForServerType(r, mode)) != null
        );
      });
    } else {
      groups = this.value.resourceTypeInfos.filter((resourceType) => {
        return (
          resources.find(
            (resource) =>
              this.isRestrictedResourceVisible(resource) &&
              licenseService.availableForServerType(resource, mode) &&
              resource.resourceTypeId == resourceType.resourceTypeId,
          ) != null
        );
      });
    }
    return groups.sort((a, b) => a.rank - b.rank);
  }
}
