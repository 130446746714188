<div class="wrapper">
    <div class="accordion" [class.expanded]="!isItemShrinked(i)" *ngFor="let order of orderList; let i = index">
        <div class="accordion-header" (click)="expandShrinkGroup(i)">
            <div class="first-part">
                <p class="heading">
                    {{ order.code }}
                </p>
            </div>
            <div class="end-part">
                <!--          <div class="date">-->
                <!--            <p>-->
                <!--              {{ order.created | date: 'EEEE, MMMM d, y, h:mm:ss' }}-->
                <!--            </p>-->
                <!--          </div>-->
                <div class="arrow">
                    <cue-icon [icon]="ICONS.cueArrowDown"></cue-icon>
                </div>
            </div>
        </div>
        <div class="accordion-content" *ngIf="!isItemShrinked(i)">
            <div class="description" *ngIf="order.note !== null">
                <p>
                    {{ order.note }}
                </p>
            </div>

            <ng-container *ngFor="let item of order.items">
                <div class="order-item">
                    <div class="image-wrapper">
                        <img [src]="item.imageUrl ?? 'assets/images/placeholder-order.svg'" />
                    </div>
                    <div class="text-wrapper">
                        <div class="item-heading">
                            <p>
                                {{ item?.displayName }}
                            </p>
                        </div>
                        <div class="item-inner-count">
                            <span *ngIf="item.note" class="item-description">
                                {{ item.note }}
                            </span>
                            <span *ngIf="!item.note" class="item-description no-description">
                                {{ translation.orders.noDescription | transloco }}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
