import { ErrorHandler, Injectable } from '@angular/core';
import { ApiService, AssistResourceBM, cacheGet, eventStatuses, fromLocalized } from '@cue/api';
import { map, Observable, of, switchMap } from 'rxjs';
import { CalendarAvailability, CalendarScheduleItem, CalendarsService, CalendarsServiceFactoryService } from '@cue/calendars';
import {
  findResourceFromUniqueIdAndDisplayName,
  generateAvailability,
  getAttendeeFromLocationAndAttendees,
  getImageCoverFromResource,
  getImageUrlFromResource,
  getPatchedAvailabilityWithEvent,
  getTimeZoneFromOfficeString,
  patchAvailabilityByRestrictions,
  patchAvailabilityByWorkingHours,
  tryToFindLocation,
} from '../utils';
import { add, differenceInMinutes, getHours, getMinutes, getSeconds, isWithinInterval } from 'date-fns';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import {
  AnonymousAssistEvent,
  AppState,
  AssistEvent,
  AssistEventType,
  FaultyAssistEvent,
  FilteredResources,
  IdentifiedAssistEvent,
  isIdentifiedAssistEvent,
  Schedule,
} from '../models';

import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AssistErrorHandler } from './error-handler';
import { utcToZonedTime } from 'date-fns-tz';
import { remove } from 'remove-accents';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private errorHandler: AssistErrorHandler;
  calendarImplementation$: Observable<CalendarsService>;

  constructor(
    private translocoService: TranslocoService,
    private apiService: ApiService,
    private configService: ConfigService,
    private store: Store<AppState>,
    private authService: AuthService,
    eHandler: ErrorHandler,
    calendarsProviders: CalendarsServiceFactoryService,
  ) {
    this.errorHandler = eHandler as AssistErrorHandler;
    this.calendarImplementation$ = calendarsProviders.getImplementation(this.authService.getMode());
  }

  private getCachedSchedules(filter: { fromDate?: Date; toDate?: Date; uniqueMails: any[]; filteredRooms: FilteredResources[] }) {
    return this.apiService.call(cacheGet(filter.fromDate, filter.toDate, filter.uniqueMails)).pipe(
      map((serverSchedules) => {
        return filter.filteredRooms.map((fr) => {
          return serverSchedules
            .filter((x) => (fr.resources as AssistResourceBM[]).find((res) => res.email === x.scheduleId) != null)
            .map((x: any) => {
              const originalResource = (fr.resources as AssistResourceBM[]).find((res) => res.email === x.scheduleId);
              if (x.error != null) {
                return {
                  scheduleId: x.scheduleId,
                  scheduleItems: [],
                  schedulingSettings: originalResource.schedulingSettings,
                  workingHours: originalResource!.timezone
                    ? {
                        timezone: originalResource.timezone,
                        daysOfWeek: originalResource.daysOfWeek,
                        endTime: originalResource.endTime,
                        startTime: originalResource.startTime,
                      }
                    : x.workingHours,
                  availabilityView: new Array(Math.floor(differenceInMinutes(filter.toDate!, filter.fromDate!) / 15) + 1)
                    .map((_) => CalendarAvailability.limited)
                    .join(''),
                  requirementId: fr.requirement.id,
                  additional: fr.additional,
                  resourceId: originalResource!.id,
                  serviceError: x.error?.message != null ? x.error.message : x.error?.responsiveService,
                };
              }

              return {
                ...x,
                schedulingSettings: originalResource.schedulingSettings,
                requirementId: fr.requirement.id,
                additional: fr.additional,
                resourceId: originalResource!.id,
              };
            });
        });
      }),
    );
  }

  private filterEventsBySettingsAndFixTimezone(events: IdentifiedAssistEvent[], settings: any, resources: any[]) {
    let checker = (arr: any[], target: any[]) => target.every((v) => arr.includes(v));
    return events.filter((e) => {
      const attendeeFromLocation = getAttendeeFromLocationAndAttendees(
        e,
        resources,
        this.translocoService.getActiveLang(),
        this.translocoService.getDefaultLang(),
      );
      const accepted = attendeeFromLocation
        ? attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
          ? true
          : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
            ? false
            : null
        : null;
      const canceled = e.canceled;

      const attendeesMails: string[] = e.attendees.map((a: any) => a.emailAddress.address).filter((x: any) => x != null);

      const states = settings.state;

      const statesFiltered =
        states.length === 4 ||
        states.length === 0 ||
        states.filter((state: any) => {
          // return   (canceled === state.value.canceled) && ( accepted ===
          // state.value.accepted);
          if (state.value.canceled === true) {
            return canceled === state.value.canceled;
          }

          if (state.value.canceled === false && canceled === false) {
            if (state.value.accepted === null) {
              return accepted === null;
            } else {
              return accepted === state.value.accepted;
            }
          } else {
            return false;
          }
        }).length > 0;

      const isResource = attendeesMails.some((m) => resources.map((x) => x.email).some((f) => f.toUpperCase() == m.toUpperCase()));

      const foundResource = resources.filter((x) => x.email.toLowerCase() == attendeeFromLocation?.emailAddress?.address.toLowerCase())[0];
      const resourceName = isResource && foundResource ? foundResource.name : attendeeFromLocation?.emailAddress.name;

      const resourceDisplayName =
        isResource && foundResource
          ? fromLocalized(foundResource.displayNameForApp, this.translocoService.getActiveLang(), this.translocoService.getDefaultLang())
          : attendeeFromLocation?.emailAddress.name;

      const stripResourceName = remove(resourceName).toLowerCase();
      const stripResourceDisplayName = resourceDisplayName != null ? remove(resourceDisplayName).toLowerCase() : null;

      const nameFiltered =
        (stripResourceName != null &&
          (settings.resourceNames.length == 0 ||
            settings.resourceNames.some((rn: string) => remove(rn).toLowerCase() === stripResourceName))) ||
        (stripResourceDisplayName != null &&
          (settings.resourceNames.length == 0 ||
            settings.resourceNames.some((rn: string) => remove(rn).toLowerCase() === stripResourceDisplayName)));
      const settingsAttendeesMails = settings.attendees.map((x: any) => x.value).filter((x: any) => x != null);
      const attendeesFiltered =
        settings.attendees.length == 0 ||
        checker(
          attendeesMails.map((x) => x.toUpperCase()),
          settingsAttendeesMails.map((x: string) => x.toUpperCase()),
        );

      return isResource && nameFiltered && attendeesFiltered && statesFiltered;
    });
  }

  private onlyUnique(value: any, index: number, self: any[]) {
    return self.indexOf(value) === index;
  }

  getConfirmStatuses(requests: { email: string; iCalUid: string; id?: string }[]) {
    return this.apiService.call(eventStatuses(requests));
  }

  getTimelinePatchedScheduleForResource(
    resource: AssistResourceBM,
    fromDate: Date,
    toDate: Date,
    existingEvent?: {
      start: Date;
      end: Date;
    },
  ) {
    const availabilityView$ = this.configService.value.cachingEnabled
      ? this.apiService.call(cacheGet(fromDate, toDate, [resource.email])).pipe(
          map((x) => {
            return {
              availabilityView: x[0].availabilityView.split('') as CalendarAvailability[],
              workingHours: x[0].workingHours,
              schedulingSettings: resource.schedulingSettings,
            };
          }),
        )
      : this.calendarImplementation$.pipe(
          switchMap((implementation) => implementation.getScheduleForEmails([resource.email], fromDate, toDate)),
          map((x) => {
            return {
              availabilityView: x[0].availabilityView.split('') as CalendarAvailability[],
              workingHours: x[0].workingHours,
              schedulingSettings: resource.schedulingSettings,
            };
          }),
        );

    return availabilityView$.pipe(
      // working hours
      map((value) => {
        const workingHours = resource!.timezone
          ? {
              timezone: resource.timezone,
              daysOfWeek: resource.daysOfWeek,
              endTime: resource.endTime,
              startTime: resource.startTime,
            }
          : value.workingHours;
        return {
          resourceTimezone: resource!.timezone,
          value: patchAvailabilityByWorkingHours(
            fromDate,
            value.availabilityView,
            workingHours,
            resource!.timezone,
            value.schedulingSettings,
          ),
          settings: value.schedulingSettings,
          fromDate,
        };
      }),
      // patchnout podle restrikci
      map(({ value, settings, fromDate, resourceTimezone }) =>
        patchAvailabilityByRestrictions(resourceTimezone, fromDate, new Date(), value, settings, resource.conditions.reservedTo),
      ),
      // minulost pryc
      // map(value => patchAvaibalityFromTePast(new Date(), fromDate, value)),

      map((value) => {
        return existingEvent != null && existingEvent != undefined
          ? getPatchedAvailabilityWithEvent(value, fromDate, existingEvent.start, existingEvent.end)
          : value;
      }),
    );
  }

  // FREE / BUSY
  getScheduleForAttendee(email: string, fromDate: Date, toDate: Date) {
    const errorView = generateAvailability(fromDate, toDate, CalendarAvailability.tentative);
    return this.calendarImplementation$.pipe(
      switchMap((implementation) => implementation.getScheduleForEmails([email], fromDate, toDate)),
      map((x) => (x[0].availabilityView == null || x[0].availabilityView.length === 0 ? errorView.join('') : x[0].availabilityView)),
    );
  }

  // FREE / BUSY
  getScheduleForAttendees(
    attendeeEmails: string[],
    fromDate: Date,
    toDate: Date,
  ): Observable<{ email: string; availabilityView: string; items: CalendarScheduleItem[] }[]> {
    const errorView = generateAvailability(fromDate, toDate, CalendarAvailability.tentative);

    if (attendeeEmails.length === 0) {
      return of([]);
    }
    return this.calendarImplementation$.pipe(
      switchMap((implementation) => implementation.getScheduleForEmails(attendeeEmails, fromDate, toDate)),
      map((x) =>
        x.map((a) => ({
          email: a.email,
          availabilityView: a.availabilityView == null || a.availabilityView.length === 0 ? errorView.join('') : a.availabilityView,
          items: a.scheduleItems,
        })),
      ),
    );
  }

  getSchedulesForResources(filteredRooms: FilteredResources[], fromDate: Date, toDate: Date): Observable<Schedule[][]> {
    const emails: string[] = [];
    filteredRooms.forEach((frooms) => {
      const toAdd = (frooms.resources as AssistResourceBM[]).map((x) => x.email);
      emails.push(...toAdd);
    });

    if (this.configService.value.cachingEnabled) {
      const uniqueMails = emails.filter(this.onlyUnique);
      return this.getCachedSchedules({
        fromDate: fromDate,
        toDate: toDate,
        uniqueMails: uniqueMails,
        filteredRooms: filteredRooms,
      });
    }

    return this.calendarImplementation$.pipe(
      switchMap((implementation) => implementation.getScheduleForEmails(emails, fromDate, toDate)),
      map((calendarSchedules) => {
        return filteredRooms.map((fr) => {
          return calendarSchedules
            .filter((cs) => (fr.resources as AssistResourceBM[]).find((res) => res.email == cs.email) != null)
            .map((cs) => {
              const originalResource = (fr.resources as AssistResourceBM[]).find((res) => res.email === cs.email);
              const data: Schedule = {
                serviceError: cs.serviceError,
                additional: false,
                availabilityView: cs.availabilityView,
                schedulingSettings: originalResource.schedulingSettings,
                workingHours: cs.workingHours
                  ? cs.workingHours
                  : {
                      timezone: originalResource.timezone,
                      daysOfWeek: originalResource.daysOfWeek,
                      endTime: originalResource.endTime,
                      startTime: originalResource.startTime,
                    },
                resourceId: originalResource.id,
                scheduleId: cs.email,
                scheduleItems: [],
                requirementId: fr.requirement.id,
              };
              return data;
            });
        });
      }),
    );
  }

  // eventy samotny

  getEventsForCollegue(identifier: string, from: Date, to: Date): Observable<IdentifiedAssistEvent[]> {
    return this.calendarImplementation$.pipe(
      switchMap((implementation) => implementation.getCalendarEvents(from, to, identifier)),
      concatLatestFrom((x) => this.store.select((x) => x.shared.resources).pipe(map((x) => x.data))),
      map(([{ events }, resources]) => {
        if (this.authService.getMode() === 'gspace') {
          events.forEach((event) => {
            const location = tryToFindLocation(
              event,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            event.locations =
              location != null
                ? [location]
                : [
                    {
                      displayName: 'N/A',
                      locationType: 'N/A',
                      locationUri: 'N/A',
                      uniqueId: 'N/A',
                      uniqueIdType: 'N/A',
                    },
                  ];
          });
        }
        return [events, resources] as const;
      }),
      switchMap(([events, resources]) => {
        const request = events
          .filter((e: any) => {
            const attendeeFromLocatioon = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            return attendeeFromLocatioon != null;
          })
          .map((e: any) => {
            const attendeeFromLocatioon = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            return {
              email: attendeeFromLocatioon!.emailAddress.address,
              iCalUid: e.iCalUId,
              id: e.id,
            };
          });

        return this.getConfirmStatuses(request).pipe(
          concatLatestFrom((x) => this.store.select((x) => x.shared.resources).pipe(map((x) => x.data))),
          map(([response, resources]) => {
            return events.map((e) => {
              let event: AssistEvent = null;
              try {
                const attendeeFromLocation = getAttendeeFromLocationAndAttendees(
                  e,
                  resources,
                  this.translocoService.getActiveLang(),
                  this.translocoService.getDefaultLang(),
                );
                if (!attendeeFromLocation) {
                  return {
                    originalEvent: e,
                    logged: new Date(),
                    assistEventType: AssistEventType.Anonymous,
                    reason: 'Cannot find event attendee from location',
                  } as AnonymousAssistEvent;
                }

                const foundResponse = response.find((rE) => rE.iCalUid === e.iCalUId);
                if (!foundResponse) {
                  return {
                    originalEvent: e,
                    logged: new Date(),
                    assistEventType: AssistEventType.Anonymous,
                    reason: 'No server response found',
                  } as AnonymousAssistEvent;
                }

                const foundResource = findResourceFromUniqueIdAndDisplayName(
                  attendeeFromLocation.emailAddress.address?.toUpperCase(),
                  attendeeFromLocation.emailAddress.name?.toUpperCase(),
                  resources,
                );

                if (!foundResource) {
                  return {
                    originalEvent: e,
                    logged: new Date(),
                    assistEventType: AssistEventType.Anonymous,
                    reason: 'Resource for event has not been found',
                  } as AnonymousAssistEvent;
                }

                const currentUserEmaIL = this.authService.getEmail();
                const currentUserUsername = this.authService.getUsername();
                const cancelMinutes = foundResource.resourceUnconfirmedEventCancelEventTime
                  ? foundResource.resourceUnconfirmedEventCancelEventTime
                  : 15;
                const higherBorderTime = add(new Date(e.start.dateTime), {
                  minutes: cancelMinutes,
                });

                const beforeMinutes =
                  foundResource && foundResource.resourceMinutesForConfirmationBeforeStartTime
                    ? foundResource.resourceMinutesForConfirmationBeforeStartTime
                    : 15;

                const lowerBorderTime = add(new Date(e.start.dateTime), {
                  minutes: -beforeMinutes,
                });

                const accepted =
                  attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
                    ? true
                    : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
                      ? false
                      : null;

                const requiresConfirmation = foundResource ? foundResource.requiresConfirmation : false;

                const timezone = getTimeZoneFromOfficeString(foundResource.timezone);
                const timeInZone = utcToZonedTime(new Date(e.start.dateTime), timezone);

                const isMidnight = getHours(timeInZone) === 0 && getMinutes(timeInZone) === 0 && getSeconds(timeInZone) === 0;

                const hours = Math.floor(foundResource.resourceUnconfirmedEventCancelEventAllDayTime! / 60);
                const minutes = foundResource.resourceUnconfirmedEventCancelEventAllDayTime! % 60;

                const confirmFromMidnight = isMidnight ? timeInZone <= new Date() : false;

                const showConfirmButton =
                  !foundResponse.canceled &&
                  !foundResponse.confirmed &&
                  requiresConfirmation &&
                  ((isWithinInterval(new Date(), {
                    start: lowerBorderTime,
                    end: higherBorderTime,
                  }) &&
                    new Date() <= new Date(e.end.dateTime)) ||
                    confirmFromMidnight);

                const showChangeButton =
                  !foundResponse.canceled &&
                  accepted &&
                  (foundResponse.confirmed || !showConfirmButton) &&
                  new Date(e.end.dateTime) >= new Date();

                const imageUrl = getImageUrlFromResource(foundResource);

                const showFinish = !foundResponse.canceled && new Date(e.start.dateTime) <= new Date();

                const identifiedEvent: IdentifiedAssistEvent = {
                  id: e.id,
                  assistEventType: AssistEventType.Identified,
                  visibility: e.visibility,
                  end: e.end,
                  start: e.start,
                  organizer: e.organizer,
                  subject: e.subject,
                  status: e.status,
                  isAllDay: e.isAllDay,
                  attendees: e.attendees,
                  accepted:
                    attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
                      ? true
                      : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
                        ? false
                        : null,
                  isOrganizer: e.isOrganizer,
                  bodyHtml: e.bodyHtml,
                  bodyPreview: e.bodyPreview,
                  locations: e.locations,
                  type: e.type,
                  onlineMeetingProvider: e.onlineMeetingProvider,
                  seriesMasterId: e.seriesMasterId,
                  canceled: foundResponse.canceled,
                  confirmed: foundResponse.confirmed,
                  resource: foundResource,
                  iCalUId: e.iCalUId,
                  orderItemsCount: foundResponse.orderItemsCount,
                  afterEnd: new Date(e.end.dateTime) < new Date(),
                  confirmUntil: higherBorderTime,
                  showConfirmButton: showConfirmButton,
                  showChangeButton: showChangeButton,
                  imageUrl: imageUrl !== null && imageUrl !== '' ? imageUrl : '/assets/images/placeholder.jpg',
                  imagePreferCover: getImageCoverFromResource(foundResource),
                  reservationsEnabled: foundResource.reservationsEnabled,
                  navigationEnabled: foundResource.navigationEnabled,
                  showFinish: showFinish,
                };
                return identifiedEvent;
              } catch (error) {
                // Proste se to nepovedlo
                return {
                  error: error,
                  logged: new Date(),
                  assistEventType: AssistEventType.Faulty,
                  originalEvent: e,
                } as FaultyAssistEvent;
              }
            });
          }),
        );
      }),
      map((y) => {
        const toLog = y.filter((e) => !isIdentifiedAssistEvent(e));
        this.errorHandler.writeEventsLog(toLog);
        return y.filter(isIdentifiedAssistEvent);
      }),
    );
  }

  getMyEventsWhereIsResource(from: Date, to: Date, resources: AssistResourceBM[]): Observable<IdentifiedAssistEvent[]> {
    return this.calendarImplementation$.pipe(
      switchMap((implementation) => implementation.getCalendarEvents(from, to, 'me')),
      //  přidat Ztka k datumumn
      map(({ events }) => {
        events.forEach((event) => {
          event.start.dateTime =
            this.authService.getMode() == 'gspace' || this.authService.getMode() == 'touchone-calendar'
              ? new Date(event.start.dateTime as any).toISOString()
              : event.start.dateTime + 'Z';
          event.end.dateTime =
            this.authService.getMode() == 'gspace' || this.authService.getMode() == 'touchone-calendar'
              ? new Date(event.end.dateTime as any).toISOString()
              : event.end.dateTime + 'Z';
        });
        return events;
      }),
      map((events) => {
        if (this.authService.getMode() === 'gspace') {
          events.forEach((event) => {
            const location = tryToFindLocation(
              event,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            event.locations =
              location != null
                ? [location]
                : [
                    {
                      displayName: 'N/A',
                      locationType: 'N/A',
                      locationUri: 'N/A',
                      uniqueId: 'N/A',
                      uniqueIdType: 'N/A',
                    },
                  ];
          });
        }
        return [events, resources] as const;
      }),
      switchMap(([events, resources]) => {
        const request = events
          .filter((e: any) => {
            const attendeeFromLocatioon = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            return attendeeFromLocatioon != null;
          })
          .map((e: any) => {
            const attendeeFromLocatioon = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            return {
              email: attendeeFromLocatioon.emailAddress.address,
              iCalUid: e.iCalUId,
              id: e.id,
            };
          });

        return this.getConfirmStatuses(request).pipe(
          map((response) => {
            return events.map((e) => {
              let event: AssistEvent = null;
              try {
                const attendeeFromLocation = getAttendeeFromLocationAndAttendees(
                  e,
                  resources,
                  this.translocoService.getActiveLang(),
                  this.translocoService.getDefaultLang(),
                );
                if (!attendeeFromLocation) {
                  return {
                    originalEvent: e,
                    logged: new Date(),
                    assistEventType: AssistEventType.Anonymous,
                    reason: 'Cannot find event attendee from location',
                  } as AnonymousAssistEvent;
                }

                const foundResponse = response.find((rE) => rE.iCalUid === e.iCalUId);
                if (!foundResponse) {
                  return {
                    originalEvent: e,
                    logged: new Date(),
                    assistEventType: AssistEventType.Anonymous,
                    reason: 'No server response found',
                  } as AnonymousAssistEvent;
                }

                const foundResource = findResourceFromUniqueIdAndDisplayName(
                  attendeeFromLocation.emailAddress.address?.toUpperCase(),
                  attendeeFromLocation.emailAddress.name?.toUpperCase(),
                  resources,
                );

                if (!foundResource) {
                  return {
                    originalEvent: e,
                    logged: new Date(),
                    assistEventType: AssistEventType.Anonymous,
                    reason: 'Resource for event has not been found',
                  } as AnonymousAssistEvent;
                }

                const currentUserEmaIL = this.authService.getEmail();
                const currentUserUsername = this.authService.getUsername();
                const cancelMinutes = foundResource.resourceUnconfirmedEventCancelEventTime
                  ? foundResource.resourceUnconfirmedEventCancelEventTime
                  : 15;
                const higherBorderTime = add(new Date(e.start.dateTime), {
                  minutes: cancelMinutes,
                });

                const beforeMinutes =
                  foundResource && foundResource.resourceMinutesForConfirmationBeforeStartTime
                    ? foundResource.resourceMinutesForConfirmationBeforeStartTime
                    : 15;

                const lowerBorderTime = add(new Date(e.start.dateTime), {
                  minutes: -beforeMinutes,
                });

                const accepted =
                  attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
                    ? true
                    : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
                      ? false
                      : null;

                const requiresConfirmation = foundResource ? foundResource.requiresConfirmation : false;

                const timezone = getTimeZoneFromOfficeString(foundResource.timezone);
                const timeInZone = utcToZonedTime(new Date(e.start.dateTime), timezone);

                const isMidnight = getHours(timeInZone) === 0 && getMinutes(timeInZone) === 0 && getSeconds(timeInZone) === 0;

                const hours = Math.floor(foundResource.resourceUnconfirmedEventCancelEventAllDayTime! / 60);
                const minutes = foundResource.resourceUnconfirmedEventCancelEventAllDayTime! % 60;

                const confirmFromMidnight = isMidnight ? timeInZone <= new Date() : false;

                const showConfirmButton =
                  !foundResponse.canceled &&
                  !foundResponse.confirmed &&
                  requiresConfirmation &&
                  ((isWithinInterval(new Date(), {
                    start: lowerBorderTime,
                    end: higherBorderTime,
                  }) &&
                    new Date() <= new Date(e.end.dateTime)) ||
                    confirmFromMidnight);

                const showChangeButton =
                  !foundResponse.canceled &&
                  accepted &&
                  (foundResponse.confirmed || !showConfirmButton) &&
                  new Date(e.end.dateTime) >= new Date();

                const imageUrl = getImageUrlFromResource(foundResource);

                const showFinish = !foundResponse.canceled && new Date(e.start.dateTime) <= new Date();

                const identifiedEvent: IdentifiedAssistEvent = {
                  id: e.id,
                  assistEventType: AssistEventType.Identified,
                  end: e.end,
                  start: e.start,
                  organizer: e.organizer,
                  visibility: e.visibility,
                  subject: e.subject,
                  status: e.status,
                  isAllDay: e.isAllDay,
                  attendees: e.attendees,
                  accepted:
                    attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
                      ? true
                      : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
                        ? false
                        : null,
                  isOrganizer: e.isOrganizer,
                  bodyHtml: e.bodyHtml,
                  bodyPreview: e.bodyPreview,
                  locations: e.locations,
                  type: e.type,
                  onlineMeetingProvider: e.onlineMeetingProvider,
                  seriesMasterId: e.seriesMasterId,
                  canceled: foundResponse.canceled,
                  confirmed: foundResponse.confirmed,
                  resource: foundResource,
                  iCalUId: e.iCalUId,
                  orderItemsCount: foundResponse.orderItemsCount,
                  afterEnd: new Date(e.end.dateTime) < new Date(),
                  confirmUntil: higherBorderTime,
                  showConfirmButton: showConfirmButton,
                  showChangeButton: showChangeButton,
                  imagePreferCover: getImageCoverFromResource(foundResource),
                  imageUrl: imageUrl !== null && imageUrl !== '' ? imageUrl : '/assets/images/placeholder.jpg',
                  reservationsEnabled: foundResource.reservationsEnabled,
                  navigationEnabled: foundResource.navigationEnabled,
                  showFinish: showFinish,
                };
                return identifiedEvent;
              } catch (error) {
                // Proste se to nepovedlo
                return {
                  error: error,
                  logged: new Date(),
                  assistEventType: AssistEventType.Faulty,
                  originalEvent: e,
                } as FaultyAssistEvent;
              }
            });
          }),
        );
      }),
      map((y) => {
        const toLog = y.filter((e) => !isIdentifiedAssistEvent(e));
        this.errorHandler.writeEventsLog(toLog);
        return y.filter(isIdentifiedAssistEvent);
      }),
    );
  }

  getReservationByICalIUid(
    from: Date,
    to: Date,
    resources: AssistResourceBM[],
    settings: any,
    iCalUid: string,
  ): Observable<IdentifiedAssistEvent> {
    return this.getReservations(from, to, resources, settings).pipe(map((response) => response.events.find((x) => x.iCalUId == iCalUid)));
  }

  getReservations(
    from: Date,
    to: Date,
    resources: AssistResourceBM[],
    settings: any,
  ): Observable<{ events: IdentifiedAssistEvent[]; nextLink: string }> {
    return this.calendarImplementation$.pipe(
      switchMap((implementation) => implementation.getCalendarEvents(from, to, 'me', settings)),
      map((x) => {
        if (this.authService.getMode() === 'gspace') {
          x.events.forEach((event) => {
            const location = tryToFindLocation(
              event,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            event.locations =
              location != null
                ? [location]
                : [
                    {
                      displayName: 'N/A',
                      locationType: 'N/A',
                      locationUri: 'N/A',
                      uniqueId: 'N/A',
                      uniqueIdType: 'N/A',
                    },
                  ];
          });
        }

        return x;
      }),
      map((e) => {
        e.events.forEach((event) => {
          event.start.dateTime =
            this.authService.getMode() == 'gspace' || this.authService.getMode() == 'touchone-calendar'
              ? new Date(event.start.dateTime as any).toISOString()
              : event.start.dateTime + 'Z';
          event.end.dateTime =
            this.authService.getMode() == 'gspace' || this.authService.getMode() == 'touchone-calendar'
              ? new Date(event.end.dateTime as any).toISOString()
              : event.end.dateTime + 'Z';
        });
        return e;
      }),
      switchMap((x) => {
        const request = x.events
          .filter((e: any) => {
            const attendeeFromLocatioon = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            return attendeeFromLocatioon != null;
          })
          .map((e: any) => {
            const attendeeFromLocatioon = getAttendeeFromLocationAndAttendees(
              e,
              resources,
              this.translocoService.getActiveLang(),
              this.translocoService.getDefaultLang(),
            );
            return {
              email: attendeeFromLocatioon!.emailAddress.address,
              iCalUid: e.iCalUId,
              id: e.id,
            };
          });

        return this.getConfirmStatuses(request).pipe(
          map((response) => {
            return {
              events: x.events.map((e) => {
                let event: AssistEvent = null;
                try {
                  const attendeeFromLocation = getAttendeeFromLocationAndAttendees(
                    e,
                    resources,
                    this.translocoService.getActiveLang(),
                    this.translocoService.getDefaultLang(),
                  );
                  if (!attendeeFromLocation) {
                    return {
                      originalEvent: e,
                      logged: new Date(),
                      assistEventType: AssistEventType.Anonymous,
                      reason: 'Cannot find event attendee from location',
                    } as AnonymousAssistEvent;
                  }

                  const foundResponse = response.find((rE) => rE.iCalUid === e.iCalUId);
                  if (!foundResponse) {
                    return {
                      originalEvent: e,
                      assistEventType: AssistEventType.Anonymous,
                      logged: new Date(),
                      reason: 'No server response found',
                    } as AnonymousAssistEvent;
                  }

                  const foundResource = findResourceFromUniqueIdAndDisplayName(
                    attendeeFromLocation.emailAddress.address?.toUpperCase(),
                    attendeeFromLocation.emailAddress.name?.toUpperCase(),
                    resources,
                  );

                  if (!foundResource) {
                    return {
                      originalEvent: e,
                      assistEventType: AssistEventType.Anonymous,
                      logged: new Date(),
                      reason: 'Resource for event has not been found',
                    } as AnonymousAssistEvent;
                  }

                  const currentUserEmaIL = this.authService.getEmail();
                  const currentUserUsername = this.authService.getUsername();
                  const cancelMinutes = foundResource.resourceUnconfirmedEventCancelEventTime
                    ? foundResource.resourceUnconfirmedEventCancelEventTime
                    : 15;
                  const higherBorderTime = add(new Date(e.start.dateTime), {
                    minutes: cancelMinutes,
                  });

                  const beforeMinutes =
                    foundResource && foundResource.resourceMinutesForConfirmationBeforeStartTime
                      ? foundResource.resourceMinutesForConfirmationBeforeStartTime
                      : 15;

                  const lowerBorderTime = add(new Date(e.start.dateTime), {
                    minutes: -beforeMinutes,
                  });

                  const accepted =
                    attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
                      ? true
                      : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
                        ? false
                        : null;

                  const requiresConfirmation = foundResource ? foundResource.requiresConfirmation : false;

                  const timezone = getTimeZoneFromOfficeString(foundResource.timezone);
                  const timeInZone = utcToZonedTime(new Date(e.start.dateTime), timezone);

                  const isMidnight = getHours(timeInZone) === 0 && getMinutes(timeInZone) === 0 && getSeconds(timeInZone) === 0;

                  const hours = Math.floor(foundResource.resourceUnconfirmedEventCancelEventAllDayTime! / 60);
                  const minutes = foundResource.resourceUnconfirmedEventCancelEventAllDayTime! % 60;

                  const confirmFromMidnight = isMidnight ? timeInZone <= new Date() : false;

                  const showConfirmButton =
                    !foundResponse.canceled &&
                    !foundResponse.confirmed &&
                    requiresConfirmation &&
                    ((isWithinInterval(new Date(), {
                      start: lowerBorderTime,
                      end: higherBorderTime,
                    }) &&
                      new Date() <= new Date(e.end.dateTime)) ||
                      confirmFromMidnight);

                  const showChangeButton =
                    !foundResponse.canceled &&
                    accepted &&
                    (foundResponse.confirmed || !showConfirmButton) &&
                    new Date(e.end.dateTime) >= new Date();

                  const imageUrl = getImageUrlFromResource(foundResource);

                  const showFinish = !foundResponse.canceled && new Date(e.start.dateTime) <= new Date();

                  const identifiedEvent: IdentifiedAssistEvent = {
                    id: e.id,
                    assistEventType: AssistEventType.Identified,
                    end: e.end,
                    start: e.start,
                    visibility: e.visibility,
                    organizer: e.organizer,
                    subject: e.subject,
                    status: e.status,
                    isAllDay: e.isAllDay,
                    attendees: e.attendees,
                    accepted:
                      attendeeFromLocation.status.response === 'accepted' || attendeeFromLocation.status.response === 'Accept'
                        ? true
                        : attendeeFromLocation.status.response === 'declined' || attendeeFromLocation.status.response === 'Decline'
                          ? false
                          : null,
                    isOrganizer: e.isOrganizer,
                    bodyHtml: e.bodyHtml,
                    bodyPreview: e.bodyPreview,
                    locations: e.locations,
                    type: e.type,
                    onlineMeetingProvider: e.onlineMeetingProvider,
                    seriesMasterId: e.seriesMasterId,
                    canceled: foundResponse.canceled,
                    confirmed: foundResponse.confirmed,
                    resource: foundResource,
                    iCalUId: e.iCalUId,
                    orderItemsCount: foundResponse.orderItemsCount,
                    afterEnd: new Date(e.end.dateTime) < new Date(),
                    confirmUntil: higherBorderTime,
                    showConfirmButton: showConfirmButton,
                    showChangeButton: showChangeButton,
                    imageUrl: imageUrl !== null && imageUrl !== '' ? imageUrl : '/assets/images/placeholder.jpg',
                    imagePreferCover: getImageCoverFromResource(foundResource),
                    reservationsEnabled: foundResource.reservationsEnabled,
                    navigationEnabled: foundResource.navigationEnabled,
                    showFinish: showFinish,
                  };
                  return identifiedEvent;
                } catch (error) {
                  // Proste se to nepovedlo
                  return {
                    error: error,
                    assistEventType: AssistEventType.Faulty,
                    logged: new Date(),
                    originalEvent: e,
                  } as FaultyAssistEvent;
                }
              }),
              nextLink: x.nextLink,
            };
          }),
        );
      }),
      map((x) => {
        return {
          events: this.filterEventsBySettingsAndFixTimezone(x.events.filter(isIdentifiedAssistEvent), settings, resources),
          nextLink: x.nextLink,
        };
      }),
    );
  }
}
