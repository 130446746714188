import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable, filter, map, switchMap, take, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  ApiService,
  calendarOAuthLogin,
  CalendarServerToken,
  CueResponse,
  logoutEWS,
  oAuthLogin,
  oAuthLogout,
  oAuthRefreshToken,
  oAuthToken,
  refresh,
  ServerToken,
  ServerTokenResponse,
  ServerType,
} from '@cue/api';
import { clearCurrentUser } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _apiTokenKey = 'api_token';
  _apiRefreshTokenKey = 'api_refresh_token';

  private oauthToken = null;

  constructor(
    private configService: ConfigService,
    private store: Store<any>,
    private router: Router,
    private apiService: ApiService,
  ) {}

  getMode() {
    return localStorage.getItem('LOGIN_MODE') as ServerType;
  }

  setMode(value: string) {
    return localStorage.setItem('LOGIN_MODE', value);
  }

  getCalendarTokenFromServer(code: string, redirectUri: string, scope: string, connectionAgentId: number): Observable<CalendarServerToken> {
    return this.apiService.call(calendarOAuthLogin(code, redirectUri, scope, connectionAgentId));
  }

  getTokenFromServer(code: string): Observable<ServerTokenResponse> {
    return this.apiService.call(oAuthLogin(code, this.configService.value.redirectUri, this.configService.value.scope));
  }

  getOAuthToken() {
    return this.oauthToken;
  }

  setOAuthToken(token: string) {
    this.oauthToken = token;
  }

  getOAuthTokenFromTheServer(): Observable<CueResponse<string>> {
    return this.apiService.call(oAuthToken());
  }

  refreshOAuthTokenFromTheServer(): Observable<CueResponse<string>> {
    return this.apiService.call(oAuthRefreshToken(this.configService.value.scope));
  }

  getApiToken() {
    return localStorage.getItem(this._apiTokenKey);
  }

  getApiRefreshToken() {
    return localStorage.getItem(this._apiRefreshTokenKey);
  }

  saveApiRefreshToken(refreshToken: string) {
    localStorage.setItem(this._apiRefreshTokenKey, refreshToken);
  }

  saveApiToken(token: string) {
    localStorage.setItem(this._apiTokenKey, token);
  }

  removeApiToken() {
    localStorage.removeItem(this._apiTokenKey);
  }

  removeApiRefreshToken() {
    localStorage.removeItem(this._apiRefreshTokenKey);
  }

  redirectToAuthorize() {
    const prompt = this.configService.value.prompt ?? 'consent';

    if (this.getMode() === 'o365') {
      const url = `https://login.microsoftonline.com/${this.configService.value.tenantId}/oauth2/v2.0/authorize?client_id=${
        this.configService.value.clientId
      }&prompt=${prompt}&response_type=code&redirect_uri=${encodeURIComponent(
        this.configService.value.redirectUri,
      )}&scope=${this.configService.value.scope}`;

      location.href = url;
    }
    if (this.getMode() === 'gspace') {
      const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${encodeURIComponent(
        this.configService.value.clientId,
      )}&access_type=offline&response_type=code&prompt=${prompt}&redirect_uri=${encodeURIComponent(
        this.configService.value.redirectUri,
      )}&scope=${encodeURIComponent(this.configService.value.scope)}`;
      location.href = url;
    }
  }

  logout() {
    const redirdctUrl =
      location.pathname != '' &&
      location.pathname != '/account/login' &&
      location.pathname != '/account/email-link' &&
      location.pathname != '/account/oauth-callback' &&
      location.pathname != '/account/guest'
        ? location.pathname + location.search
        : undefined;
    if (redirdctUrl !== undefined) {
      sessionStorage.setItem('redirect_url', redirdctUrl);
    }
    this.removeApiToken();
    this.removeApiRefreshToken();

    this.router.navigate(['/', 'account', 'login'], {
      queryParams: {
        return_url: redirdctUrl,
      },
    });
  }

  refreshTokenRequest() {
    const accessToken = this.getMode() === 'ews' ? this.getApiToken() : null;
    const refreshToken = this.getApiRefreshToken();
    return this.apiService.call(refresh(refreshToken, accessToken));
  }

  logoutAndLogoutFromServer(): Observable<any> {
    if (this.getMode() == 'ews') {
      return this.apiService.call(logoutEWS()).pipe(
        switchMap((_) => {
          this.removeApiToken();
          this.removeApiRefreshToken();
          this.store.dispatch(clearCurrentUser());
          return this.router.navigate(['/account/login']);
        }),
      );
    } else if (this.getMode() == 'touchone-calendar') {
      this.removeApiToken();
      this.removeApiRefreshToken();
      this.store.dispatch(clearCurrentUser());
      return of(this.router.navigate(['/account/login']));
    } else {
      return this.apiService.call(oAuthLogout()).pipe(
        switchMap((_) => {
          this.removeApiRefreshToken();
          this.removeApiToken();
          this.oauthToken = null;
          this.store.dispatch(clearCurrentUser());
          return this.router.navigate(['/', 'account', 'login']);
        }),
      );
    }
  }

  getName(): string {
    // ne uplne idealni, ale melo by to fungovat
    let name: string = '';
    this.store
      .select((x) => x.shared.currentUser)
      .pipe(
        filter((x) => !x.loading && !x.error),
        map((x) => x.data!.name),
        take(1),
      )
      .subscribe((x) => (name = x));
    return name;
  }

  getEmail(): string {
    let email = '';
    this.store
      .select((x) => x.shared.currentUser)
      .pipe(
        filter((x) => !x.loading && !x.error),
        map((x) => x.data!.email),
        take(1),
      )
      .subscribe((x) => (email = x));
    return email;
  }

  getUsername(): string {
    let username = '';
    this.store
      .select((x) => x.shared.currentUser)
      .pipe(
        filter((x) => !x.loading && !x.error),
        map((x) => x.data!.username),
        take(1),
      )
      .subscribe((x) => (username = x));
    return username;
  }
}
