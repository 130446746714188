import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, EffectNotification, OnRunEffects, concatLatestFrom } from '@ngrx/effects';

import { combineLatest, merge, Observable, of, timer, exhaustMap, filter, map, skipUntil, switchMap } from 'rxjs';
import * as UserActions from '../actions/user.actions';
import * as SchedulesActions from '../actions/schedules.actions';

import { Store } from '@ngrx/store';
import { getNextQuarter } from '../utils';

import { allResources, currentUser } from '../selectors';
import { favouriteAdded, favouriteRemoved } from '../actions/resources.actions';
import { loadFavourites } from '../actions/schedules.actions';
import { add } from 'date-fns';
import { ApiService, favourireIds } from '@cue/api';
import { CalendarsServiceFactoryService } from '@cue/calendars';
import { DataService } from '../services/data.service';
import { ConfigService, AuthService } from '../services';
import { AppState } from '../models';
import { withLatestFrom } from 'rxjs/operators';

@Injectable()
export class FavouritesEffects implements OnRunEffects {
  // Remove after server
  refreshFavouritesEvery10Secs$ = createEffect(() =>
    merge(timer(500, 15000), this.actions$.pipe(ofType(favouriteAdded, favouriteRemoved))).pipe(
      concatLatestFrom(() => this.store.select(currentUser)),
      filter(([_, user]) => user?.data != null),
      map(loadFavourites),
    ),
  );

  loadFavourites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchedulesActions.loadFavourites),
      switchMap((action) =>
        combineLatest([
          this.apiService.call(favourireIds()),
          of(action),
          this.store.select(allResources).pipe(filter((x) => !x.loading && x.data!.length > 0)),
        ]),
      ),
      exhaustMap(([favouriteIds, action, packedResources]) => {
        if (favouriteIds.length === 0) {
          return of(
            SchedulesActions.favouritesLoaded({
              schedules: [],
              start: new Date(),
            }),
          );
        }
        const favouriteResources = packedResources.data!.filter(
          (x) => favouriteIds.find((a) => a.toUpperCase() === x.id.toUpperCase()) != null,
        );
        const newNow = getNextQuarter(new Date());
        return this.dataService
          .getSchedulesForResources(
            [
              {
                requirement: {
                  id: 'favourites',
                } as any,
                resources: favouriteResources,
                additional: false,
              },
            ],
            newNow,
            add(new Date(), {
              days: 10,
            }),
          )
          .pipe(
            map((response) => {
              return SchedulesActions.favouritesLoaded({
                schedules: response[0] ? response[0] : [],
                start: newNow,
              });
            }),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiService: ApiService,
    private dataService: DataService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return resolvedEffects$.pipe(skipUntil(this.actions$.pipe(ofType(UserActions.userLoaded))));
  }
}
