import { Injectable, signal, WritableSignal } from '@angular/core';
import { ResourcePoiIdentificationCrate } from '../models';

@Injectable()
export class CurrentMapItemService {
  startItemResourceOrPoi: WritableSignal<ResourcePoiIdentificationCrate> = signal(null);
  targetItemResourceOrPoi: WritableSignal<ResourcePoiIdentificationCrate> = signal(null);

  currentItemResourceId: WritableSignal<number> = signal(null);
  currentItemQrCodeId: WritableSignal<string> = signal(null);

  constructor() {}

  updateCurrentItemId(resourceId: number, qrCodeId: string) {
    //POI has resourceId AND qrCodeId - but we want to highlight ONLY QRCODE
    if (resourceId && qrCodeId) {
      this.currentItemQrCodeId.set(qrCodeId);
      this.currentItemResourceId.set(null);
      return;
    }
    this.currentItemResourceId.set(resourceId);
    this.currentItemQrCodeId.set(qrCodeId);
  }

  updateStartResourcePOI(resourceId: number, qrCodeId: string) {
    this.startItemResourceOrPoi.set({
      resourceId: resourceId,
      qrCodeId: qrCodeId,
    });
  }

  updateTargetResourcePOI(resourceId: number, qrCodeId: string) {
    this.targetItemResourceOrPoi.set({
      resourceId: resourceId,
      qrCodeId: qrCodeId,
    });
  }

  resetStartTargetAndCurrentItemPoints() {
    this.startItemResourceOrPoi.set(null);
    this.targetItemResourceOrPoi.set(null);
    this.currentItemResourceId.set(null);
    this.currentItemQrCodeId.set(null);
  }
}
