<ng-content></ng-content>
<kendo-multiselect
    (focusin)="focus()"
    (focusout)="blur()"
    *ngIf="valueNormalizer"
    [valuePrimitive]="valuePrimitive"
    [kendoDropDownFilter]="dropdownFilter"
    [formControl]="$any(_control)"
    [data]="data"
    [textField]="textField"
    [filterable]="filterable"
    [valueField]="valueField"
    [popupSettings]="popupSettings"
    [placeholder]="placeholder"
    [valueNormalizer]="valueNormalizer"
    (open)="open.emit($event)"
    [value]="value"
    (valueChange)="valueChange.emit($event)"
    (filterChange)="filterChange.emit($event)"
    [allowCustom]="allowCustom"
>
    <ng-container *ngIf="itemTemplate">
        <ng-template kendoMultiSelectItemTemplate let-item>
            <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="tagTemplate">
        <ng-template kendoMultiSelectTagTemplate let-item>
            <ng-container [ngTemplateOutlet]="tagTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="noDataTemplate">
        <ng-template kendoMultiSelectNoDataTemplate>
            <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
        </ng-template>
    </ng-container>
</kendo-multiselect>
