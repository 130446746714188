<div class="accordion" [class.expanded]="accordionExpanded">
    <div class="accordion-header text-[--text-heading]" (click)="accordionExpanded = !accordionExpanded">
        <div class="first-part">
            <p class="m-0 desktop-normal-bold-strong text-[--text-heading]">
                {{ openClosedCaption() | transloco }}
                <ng-container *ngIf="!isOpen">
                    <span
                        class="desktop-xs-descriptive text-[--text-description]"
                        [innerHTML]="whenOpensCaption() | transloco: { hours: tillStart().hours, minutes: tillStart().minutes }"
                    ></span>
                </ng-container>
            </p>
        </div>
        <div class="end-part">
            <div class="arrow">
                <cue-icon [icon]="ICONS.cueArrowDown"></cue-icon>
            </div>
        </div>
    </div>
    <div class="accordion-content text-[--text-description]" *ngIf="accordionExpanded">
        <div class="flex flex-col gap-[10px]">
            <ng-container *ngIf="isDifferentTimezone()">
                <div class="border mx-4"></div>
                <div class="flex flex-row gap-2 mr-4">
                    <cue-icon [icon]="ICONS.cueExclamation"></cue-icon>

                    <div class="desktop-xs-descriptive">
                        {{ translation.workingHours.differentTimezone | transloco }}
                        <div class="p-1"></div>
                        {{ translation.workingHours.locationTimezone | transloco }}
                        <span class="desktop-xs-bold-descrioptive-strong"> {{ ianaTimeZone() }} ({{ resourceTimeZoneOffset() }}) </span>
                    </div>
                </div>
                <div class="border mx-4"></div>
            </ng-container>
            <div class="grid grid-cols-[auto_1fr] mx-2 gap-x-5 gap-y-2 desktop-xs-descriptive">
                <ng-container *ngFor="let day of daysForLocale()">
                    <div [class.desktop-xs-bold-descrioptive-strong]="day.today">
                        {{ getDayTranslation(day.display) | transloco }}
                    </div>
                    <div [class.desktop-xs-bold-descrioptive-strong]="day.today">
                        <ng-container *ngIf="day.active">
                            {{ startHours() | date: 'shortTime' }} -
                            {{ endHours() | date: 'shortTime' }}
                        </ng-container>

                        <ng-container *ngIf="!day.active">
                            {{ translation.workingHours.closed | transloco }}
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
