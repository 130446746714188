import { Component, Input, Output, EventEmitter } from '@angular/core';
import { translation } from '@assist/shared/translations';
import { AssistPropertyBM, LocalizedString } from '@cue/api';
import { NavigationDetailInfo } from '@assist/navigation/services';
import { AddinConfigService } from '@outlook-addin/shared';
import { Pack } from '@assist/shared/data';
import { cueAction, cueArrowRight, cueClose, cueExternalLink, cueRefresh, cueCheck, IconComponent } from '@cue/ui/icons';
import { ResourceAssignType } from '@outlook-addin/cue-http';
import { LoadingComponent } from '@cue/loading';
import { TranslocoModule } from '@ngneat/transloco';
import { AssignToPipe, AssignToTranslateKeyToPipe, ResourceExternalUrlPipe } from '../shared/pipes';
import { ImageWidthPipe, LocalizedStringPipe } from '@cue/utilities';
import { ResourceMapComponent } from '../reservation/resource-map/resource-map.component';
import { ImageComponent } from '@cue/images';
import { CommonModule } from '@angular/common';
import { CustomUnsafeSanitizePipe } from '@cue/security';
import { WorkingHoursComponent } from '@cue/ui';

@Component({
  selector: 'addin-resource-summary-detail',
  templateUrl: './resource-summary-detail.component.html',
  styleUrls: ['./resource-summary-detail.component.scss'],
  standalone: true,
  imports: [
    LoadingComponent,
    IconComponent,
    TranslocoModule,
    AssignToTranslateKeyToPipe,
    AssignToPipe,
    ResourceExternalUrlPipe,
    ImageWidthPipe,
    ResourceMapComponent,
    ImageComponent,
    CommonModule,
    LocalizedStringPipe,
    CustomUnsafeSanitizePipe,
    WorkingHoursComponent,
  ],
})
export class ResourceSummaryDetailComponent {
  ICONS = {
    cueClose,
    cueArrowRight,
    cueAction,
    cueRefresh,
    cueExternalLink,
    cueCheck,
  };

  resourceAssignType = ResourceAssignType;
  translation = translation;
  currentImage = undefined;

  @Input() isResourceSelected: boolean;
  @Input() resourceDetail: Pack<NavigationDetailInfo>;
  @Input() isLoading: boolean;

  @Output() refresh = new EventEmitter<void>();
  @Output() selectDeselectResource: EventEmitter<number> = new EventEmitter<number>();
  @Output() closeResourceDetail: EventEmitter<any> = new EventEmitter<any>();
  apiUrl: string;
  constructor(private configService: AddinConfigService) {
    this.apiUrl = this.configService.value.apiURL;
  }

  getSimpleFiltersByResourceTypeId(detail: NavigationDetailInfo) {
    return this.getFiltersFromNavigationDetail(detail).filter((x) => x.dataTypeId !== 5);
  }

  getEnumerableFiltersByResourceTypeId(detail: NavigationDetailInfo) {
    return this.getFiltersFromNavigationDetail(detail).filter((x) => x.dataTypeId === 5 && x.value?.length > 0);
  }

  private getFiltersFromNavigationDetail(detail: NavigationDetailInfo): { name: LocalizedString; value: any; dataTypeId: number }[] {
    return detail.properties
      ?.map((property) => ({
        name: property.name,
        dataTypeId: property.propertyTypeId,
        value: this.getValueFromProperty(property),
      }))
      .filter((x) => x.value);
  }

  getValueFromProperty(property: AssistPropertyBM & { propertyTypeId: number; valueChoices: any[] }) {
    switch (property.propertyTypeId) {
      case 1: {
        return {
          value: property.valueBoolean,
          imageUrl: property.file,
        };
      }
      case 2: {
        return {
          value: property.valueNumber,
          imageUrl: property.file,
        };
      }
      case 3: {
        return {
          value: property.valueDecimal,
          imageUrl: property.file,
        };
      }
      case 4: {
        return {
          value: property.valueText,
          imageUrl: property.file,
        };
      }
      case 5: {
        return property.valueChoices.map((x) => ({
          title: x.value,
          imageUrl: x.imageUrl,
        }));
      }
      case 6: {
        return {
          value: property.valueHTMLContent,
          imageUrl: property.file,
        };
      }
      default:
        return null;
    }
  }

  navigateToResourceDetailInAssist(url: string) {
    window.open(url, '_blank');
  }
}
