<button [routerLink]="'../'">back to main page</button>
<ng-container #userAuthorized>
    <div [formGroup]="form">
        <div class="date-container">
            from:
            <input type="datetime-local" formControlName="startDate" /><br />
            <span class="error" *ngIf="form.get('startDate').touched && form.get('startDate').hasError('required')"
                >Vyplňte prosím datum OD</span
            >
            to:
            <input type="datetime-local" formControlName="endDate" /><br />
            <span class="error" *ngIf="form.get('endDate').touched && form.get('endDate').hasError('required')"
                >Vyplňte prosím datum DO</span
            >
        </div>

        <div class="colleagueList" formArrayName="colleagueEmailList">
            <div>COLLEAGUES</div>
            <button type="button" (click)="addUser()">+</button>
            <div *ngFor="let colleague of colleagueEmailList.controls; let i = index">
                <label for="colleague-{{ i }}">Email:</label>
                <input id="colleague-{{ i }}" type="text" [formControlName]="i" />
                <button type="button" (click)="deleteUser(i)">x</button>
            </div>
        </div>
        <button (click)="findMeetingTime()">find Meeting times</button>
    </div>

    <ng-container *ngIf="meetingSuggestions$ | async as meetingSuggestions">
        <ng-container *ngIf="meetingSuggestions.meetingTimeSuggestions?.length > 0; else noData">
            <h1>MEETING TIMES:</h1>
            <div class="reason" *ngFor="let suggestion of meetingSuggestions.meetingTimeSuggestions">
                Od: {{ suggestion.meetingTimeSlot.start.dateTime | date: 'short' }} Do:
                {{ suggestion.meetingTimeSlot.end.dateTime | date: 'short' }}<br />
                reason: {{ suggestion.suggestionReason }}<br />
                confidence: {{ suggestion.confidence }}%<br />
            </div>
        </ng-container>
        <ng-template #noData>
            <h1>NO MEETING TIMES</h1>
            <div *ngIf="meetingSuggestions?.emptySuggestionsReason">Reason: {{ meetingSuggestions?.emptySuggestionsReason }}</div>
        </ng-template>
    </ng-container>
</ng-container>
